.user-information {
    .details-table {
        tr td:last-child {
            text-align: right;
        }
    }
    .roles-table {
        tr th:last-child {
            text-align: right;
        }
    }
    .details-locked {
        @extend .details-table;
    }

}
@media only screen and (min-width: 1024px) {
    .user-information {
        .details-locked {
            height: 20.5rem; // 325px;
        }
    }
}