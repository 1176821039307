.site-note {
  background: #e7f6f8;
  border-color: #99deea;
}

.public-home-card-container {
  border-width: 2px;
  border-color: #c9c9c9;
  background-color: #FFF;
  border-style: solid;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  border-radius: 16px;
  p {
    margin-bottom: 1.5rem;
  }
  .usa-button--fluid {
    font-size: 1.4rem;
    padding: 12px 24px;
  }
  .employee-login {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.text-primary {
  color: #005ea2;
}

@media screen and (max-width: 520px) {
  .text-primary {
    line-height: 1.2;
  }
}

.apph-small {
  font-size: 1.2rem;
}

@media screen and (min-width: 1440px) {
  .apph-small {
    font-weight: 700;
    letter-spacing: 0.01875rem;
    text-transform: none;
  }
}

.card-heading {
  font-size: 0.97rem;
  line-height: 1.2;
  letter-spacing: 0.09rem;
}

.usa-button {
  &.usa-button {
    &--fluid {
      width: 100%;
    }
  }
}

